@import 'utils/variables';
@import 'components/type';

.loaded{
    .navbar {
        $this: ".navbar";
        transition-property: height, width, max-width, top, transform;
        transition-duration: 0.3s, 0.4s, 0.4s, 0.5s, 0.7s;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);

        &__navigation {
            transition-property: margin-top, width;
            transition-duration: 0.3s, 0.4s;
            transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);

            #{$this}__logo{
                transition: opacity 0.3s cubic-bezier(0.33, 1, 0.68, 1);
            }

            #{$this}__back {
                transition: opacity 0.3s cubic-bezier(0.33, 1, 0.68, 1);
            }

            #{$this}__button {
                transition-property: color, padding, background-color;
                transition-duration: 0.3s, 0.3s, 0.3s;
                transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
            }
        }
    }

    .homepage{
        .parent{
            transition: width 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        }
    }
    .works{
        &__link{
            transition: color 0.3s cubic-bezier(0.33, 1, 0.68, 1), background-size 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        }
    }
}

body{
    background-color: $c-bg;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}

.navbar{
    $this: &;
    z-index: 3;
    position: fixed;
    width: calc(100vw - 600px);
    max-width: calc(1920px - 300px);
    top:37.5px;
    height:75px;
    background-color: $c-container;
    border-radius: 28px;
    display: flex;
    flex-direction: column;

    left: 50%;
    transform: translate(-50%, 0);
    margin: auto;

    &__navigation {
        display: flex;
        justify-content: space-between;
        margin-left: 37.5px;
        margin-right: 37.5px;
        align-items: center;
    }

    &__logo {
        opacity:1;
        visibility:visible;
        height: 45px;
        width: 45px;
        path {
            fill: $c-text-container;
        }
    }

    &__back{
        position:absolute;
        opacity:0;
        visibility:hidden;
        height: 0;
        width: 0;

        cursor: pointer;
        background: none;
        border:none;
        path {
            fill: $c-text-container;
        }

        &::after{
            pointer-events: none;
            z-index: -1;
            opacity:0;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 0;
            border-radius: 50px;
            background-color: $c-secondary-container;
            transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        }

        &:hover, &:focus, &:active{
            path{
                fill: $c-text-secondary-container;
                transition: fill 0.3s cubic-bezier(0.33, 1, 0.68, 1);
            }
            &:after{
                pointer-events: none;
                opacity:1;
                left: -18.75px;
                padding: 0 18.75px 0 18.75px;
            }
        }
    }

    &__button {
        display:inline-block;
        position:relative;
        box-sizing: border-box;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        border-radius: 50px;
        cursor: pointer;
        outline: inherit;
        color: $c-text-container;
        transition-property: color, padding, background-color;
        transition-duration: 0.3s, 0.3s, 0.3s;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);

        &:hover, &:focus, &:active{
            color: $c-text-secondary-container;
            background-color: $c-secondary-container;
            padding: 0 18.75px 0 18.75px;
            transition-property: color, padding, background-color;
            transition-duration: 0.3s, 0.3s, 0.3s;
            transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
        }
    }

    &__aboutme{
        opacity: 0;
        width: calc(100vw - 675px);
        max-width: calc(1920px - 300px);
        height: 0;
        margin-left: 37.5px;
        margin-right: 37.5px;
        visibility: hidden;
        transition-property: visibility, opacity, width, max-width;
        transition-duration: 0.1s, 0.1s, 0.2s, 0.2s;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    }

    &--deploy{
        $this: ".navbar";
        overflow-y: auto;
        position:fixed;
        align-items: start;
        // height: calc(100vh - 300px);
        width: calc(100vw - 295px);
        max-width: 1925px;
        max-height: calc(100vh - 300px);
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);

        #{$this}__navigation {
            margin-top: 37.5px;
            position:sticky;
            top:37.5px;
            background-color: $c-container;
            width: calc(100% - 75px);
            transition-property: margin-top, width;
            transition-duration: 0.3s, 0.4s;
            transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
        }

        #{$this}__logo {
            opacity:0;
            visibility:hidden;
            height: 0px;
            width: 0px;
            path {
                fill: $c-text-container;
            }
        }

        #{$this}__back{
            opacity:1;
            visibility:visible;
            height: 45px;
            width: 45px;
            path {
                fill: $c-text-container;
            }
        }

        #{$this}__button--active {
            color: $c-text-secondary-container;
            border-radius: 50px;
            background-color: $c-secondary-container;
            padding: 0 18.75px 0 18.75px;
            transition-property: color, padding, background-color;
            transition-duration: 0.3s, 0.3s, 0.3s;
            transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
        }

        #{$this}__aboutme{
            width: calc(100% - 75px);
            margin-left: 37.5px;
            margin-right: 37.5px;
            height:100%;
            max-width: calc(1920px - 75px);
            visibility: visible;
            opacity: 1;
            transition-property: visibility, opacity, width, max-width;
            transition-duration: 0.3s, 0.3s, 0.4s, 0.4s;
            transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
        }
    }
}

.background{
    z-index: 2;
    opacity:0;
    height:100vh;
    width:100vw;
    background-color: rgba(0,0,0,0.5);
    position:fixed;
    top:0;
    left:0;
    z-index:2;
    visibility: hidden;
    transition: opacity 0.3s cubic-bezier(0.33, 1, 0.68, 1), visibility 0.3s cubic-bezier(0.33, 1, 0.68, 1);

    &--active{
        opacity:1;
        visibility:visible;
        transition: opacity 0.3s cubic-bezier(0.33, 1, 0.68, 1), visibility 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    }
}

.homepage {
    z-index: 1;
    $this: &;
    display: flex;
    height: 100vh;
    align-items: center;
    overflow: hidden;
    justify-content: center;

    &__parent {
        display: flex;
        margin-left: 150px;
        margin-right: 150px;
        height:min-content;
        width: 100%;
        max-width: 1920px;
        justify-content: space-between;
        align-items: center;
    }

    &__info {
        height:min-content;
    }

    &__socials{
        display: flex;
        justify-content: flex-start;
        gap:37.5px;
    }

    &__social {
        display: inline-block;
        position:relative;
        height:48px;
        width:48px;
        svg {
            width:48px;
            height:48px;
            path {
                fill: $c-text;
            }
        }

        &::after{
            pointer-events: none;
            z-index: -1;
            opacity:0;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 0;
            border-radius: 50px;
            background-color: $c-container;
            transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        }

        &:hover, &:focus, &:active{
            svg {
                path {
                    fill: $c-text-container;
                }
            }

            &::after{
                pointer-events: none;
                opacity:1;
                left: -18.75px;
                top: -18.75px;
                padding: 18.75px;
            }
        }
    }

    &__picture{
        height: inherit;
    }
}

.works{
    z-index: 1;
    $this: &;
    margin: 0 auto 0 auto;
    flex-direction: column;
    width: calc(100vw - 300px);
    max-width: 1920px;

    div{
        margin-bottom: 150px;
    }

    &__paragraph{
        display:flex;
        justify-content: space-between;
        gap: 37.5px;
        white-space: pre-line;
    }

    &__link{
        flex-shrink: 0;
        position:relative;
        display:block;
        height:300px;
        width:600px;
        position:relative;
        color: $c-text-container;
        background-color: $c-container;
        background-image:
            linear-gradient(
                rgba(68, 73, 48, 0.4),
                rgba(68, 73, 48, 0.4)
            ),
            url('../assets/images/ndf1.gif');
        background-position: center;
        background-size: 100%;
        text-decoration: none;
        border-radius: 50px;
        transition: background-size 0.3s cubic-bezier(0.33, 1, 0.68, 1);

        &--ndf1{
            background-image:
            linear-gradient(
                rgba(68, 73, 48, 0.4),
                rgba(68, 73, 48, 0.4)
            ),
            url('../assets/images/ndf1.gif');
        }

        &--tfa{
            background-image:
            linear-gradient(
                rgba(68, 73, 48, 0.4),
                rgba(68, 73, 48, 0.4)
            ),
            url('../assets/images/tfa.gif');
        }

        &--gendesign{
            background-image:
            linear-gradient(
                rgba(68, 73, 48, 0.4),
                rgba(68, 73, 48, 0.4)
            ),
            url('../assets/images/gendesign.gif');
        }

        &--iolce{
            background-image:
            linear-gradient(
                rgba(68, 73, 48, 0.4),
                rgba(68, 73, 48, 0.4)
            ),
            url('../assets/images/iolce.gif');
        }

        &::after{
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
            pointer-events: none;
            opacity:1;
            content: "";
            position: absolute;
            bottom: 18.75px;
            right: 18.75px;
            width: 75px;
            height: 75px;
            padding: 0;
            border-radius: 28px;
            background-color: $c-secondary-container;
            background-image:
            url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="%23bcece1"><path d="M280-280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h120q17 0 28.5 11.5T440-640q0 17-11.5 28.5T400-600H280q-50 0-85 35t-35 85q0 50 35 85t85 35h120q17 0 28.5 11.5T440-320q0 17-11.5 28.5T400-280H280Zm80-160q-17 0-28.5-11.5T320-480q0-17 11.5-28.5T360-520h240q17 0 28.5 11.5T640-480q0 17-11.5 28.5T600-440H360Zm200 160q-17 0-28.5-11.5T520-320q0-17 11.5-28.5T560-360h120q50 0 85-35t35-85q0-50-35-85t-85-35H560q-17 0-28.5-11.5T520-640q0-17 11.5-28.5T560-680h120q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H560Z"/></svg>'),
            linear-gradient(
                rgba(68, 73, 48, 0.0),
                rgba(68, 73, 48, 0.0)
            );
            background-position: center;
            background-size: 75%;
            background-repeat: no-repeat;
            transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        }

        &:hover{
            background-size: 110%;
            transition: background-size 0.3s cubic-bezier(0.33, 1, 0.68, 1);

            &:after{
                width:85px;
                height:85px;
                background-color: $c-text-secondary-container;
                background-image:
                url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="%23214e46"><path d="M280-280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h120q17 0 28.5 11.5T440-640q0 17-11.5 28.5T400-600H280q-50 0-85 35t-35 85q0 50 35 85t85 35h120q17 0 28.5 11.5T440-320q0 17-11.5 28.5T400-280H280Zm80-160q-17 0-28.5-11.5T320-480q0-17 11.5-28.5T360-520h240q17 0 28.5 11.5T640-480q0 17-11.5 28.5T600-440H360Zm200 160q-17 0-28.5-11.5T520-320q0-17 11.5-28.5T560-360h120q50 0 85-35t35-85q0-50-35-85t-85-35H560q-17 0-28.5-11.5T520-640q0-17 11.5-28.5T560-680h120q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H560Z"/></svg>'),
                linear-gradient(
                    rgba(68, 73, 48, 0),
                    rgba(68, 73, 48, 0)
                );
                background-position: center;
                background-size: 75%;
                background-repeat: no-repeat;
                transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
            }
        }
    }
}

.animations{
    &__star {
        z-index: -1;
        position: absolute;
        top: -250px;
        left: -120px;
        width: 30%;
        max-width: 700px;

        path{
            stroke: $c-outline-on-bg;
            stroke-width: 1px;
            stroke-linejoin: round;
        }

        &--rotating {
            -webkit-animation: rotating 200s linear infinite;
            -moz-animation: rotating 200s linear infinite;
            -ms-animation: rotating 200s linear infinite;
            -o-animation: rotating 200s linear infinite;
            animation: rotating 200s linear infinite;
            }
    }

    &__clover {
        z-index: -1;
        position: absolute;
        bottom: -250px;
        right: -150px;
        width: 30%;
        max-width: 700px;

        path{
            stroke: $c-outline-on-bg;
            stroke-width: 0.44px;
            stroke-linejoin: round;
        }

        &--rotating {
            -webkit-animation: rotating 200s linear infinite;
            -moz-animation: rotating 200s linear infinite;
            -ms-animation: rotating 200s linear infinite;
            -o-animation: rotating 200s linear infinite;
            animation: rotating 200s linear infinite;
            }
    }
}


@-webkit-keyframes rotating{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    }
    @keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    .navbar {
        $this: &;
        width: calc(100vw - 50px);
        max-width: 100%;
        height: 50px;
        border-radius: 20px;
        top: 18.75px;

        &__navigation{
            margin-left: 18.75px;
            margin-right: 18.75px;
        }

        &__logo {
            height: 35px;
            width: 35px;
        }

        &__back {
            height: 35px;
            width: 35px;
        }

        &__aboutme {
            width: calc(100vw - 50px);
            max-width: 100%;
            margin-left: 20px;
            margin-right: 20px;
        }

        &--deploy{
            $this: ".navbar";
            width: calc(100vw - 50px);
            max-width: 100%;
            height:100px;
            max-height: calc(100vh - 100px);
            top:50%;

            #{$this}__navigation{
                margin-left: 37.5px;
                margin-right: 37.5px;
            }

            #{$this}__back {
                height: 35px;
                width: 35px;
            }
        }
    }

    .homepage {
        $this: &;
        flex-direction: column;
        padding: 0 25px 0 25px;

        &__parent {
            margin-left: 0;
            margin-right: 0;
            flex-direction: column-reverse;
            align-items: center;
        }

        &__info {
            margin-bottom: 20px;
        }

        &__socials {
            gap:37.5px;
        }

        &__social {
            height: 40px;
            width: 40px;

            svg {
                height: 40px;
                width: 40px;
            }
        }

        &__picture {
            width: 75%;
        }
    }

    .works {
        $this: &;
        width: calc(100vw - 50px);
        max-width: 100%;

        div {
            margin-bottom: 75px;
        }

        &__paragraph {
            flex-direction: column;
            align-items: center;
            gap: 37.5px;
        }

        &__link {
            width: 100%;
            height: 200px;
            border-radius: 20px;

            &::after {
                width: 50px;
                height: 50px;
                border-radius: 15px;
            }

            &:hover, &:focus, &:active{
                &:after {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }

    .animations{
        &__star {
            z-index: -1;
            position: absolute;
            top: -100px;
            left: -100px;
            width: 60%;
            max-width: 700px;
        }

        &__clover {
            z-index: -1;
            position: absolute;
            bottom: -100px;
            right: -100px;
            width: 60%;
            max-width: 700px;
        }
    }
}

@media (max-width: 1024px) and (min-width: 769px) {
    .navbar {
        $this: &;
        width: calc(100vw - 200px);
        max-width: 100%;
        height: 65px;
        border-radius: 25px;

        &__logo {
            height: 40px;
            width: 40px;
        }

        &__back {
            height: 40px;
            width: 40px;
        }

        &__aboutme {
            width: calc(100vw - 100px);
            max-width: 100%;
            margin-left: 30px;
            margin-right: 30px;
        }

        &--deploy {
            $this: ".navbar";
            width: calc(100vw - 100px);
            max-width: 100%;
            max-height: calc(100vh - 150px);

            #{$this}__back {
                height: 40px;
                width: 40px;
            }
        }
    }

    .homepage {
        $this: &;
        flex-direction: column;
        padding: 0 50px 0 50px;

        &__parent {
            margin-left: 0;
            margin-right: 0;
            flex-direction: column-reverse;
            align-items: center;
        }

        &__info {
            margin-bottom: 30px;
            text-align: center;
        }

        &__socials {
            gap:37.5px;
            justify-content: center;
        }

        &__social {
            height: 45px;
            width: 45px;

            svg {
                height: 45px;
                width: 45px;
            }
        }

        &__picture {
            width: 65%;
            max-height: 75%;
            object-fit: contain;
        }
    }

    .works {
        $this: &;
        width: calc(100vw - 100px);
        max-width: 100%;

        div {
            margin-bottom: 100px;
        }

        &__paragraph {
            flex-direction: column;
            align-items: center;
            gap: 37.5px;
        }

        &__link {
            width: 100%;
            height: 250px;
            border-radius: 25px;

            &::after {
                width: 60px;
                height: 60px;
                border-radius: 20px;
            }

            &:hover, &:focus, &:active{
                &:after {
                    width: 70px;
                    height: 70px;
                }
            }
        }
    }

    .animations{

        &__star {
            z-index: -1;
            position: absolute;
            top: -160px;
            left: -150px;
            width: 50%;
            max-width: 700px;
        }

        &__clover {
            z-index: -1;
            position: absolute;
            bottom: -100px;
            right: -150px;
            width: 40%;
            max-width: 700px;
        }
    }
}

@media (max-width: 1280px) and (min-width: 1025px) {
    .navbar {
        $this: &;
        width: calc(100vw - 200px);
        max-width: 100%;
        height: 70px;
        border-radius: 30px;

        &__logo {
            height: 45px;
            width: 45px;
        }

        &__back {
            height: 45px;
            width: 45px;
        }

        &__aboutme {
            width: calc(100vw - 150px);
            max-width: 100%;
            margin-left: 40px;
            margin-right: 40px;
        }

        &--deploy {
            $this: ".navbar";
            width: calc(100vw - 150px);
            max-width: 100%;
            max-height: calc(100vh - 150px);

            #{$this}__back {
                height: 45px;
                width: 45px;
            }
        }
    }

    .homepage {
        $this: &;
        flex-direction: column;
        padding: 0 75px 0 75px;

        &__parent {
            margin-left: 0;
            margin-right: 0;
            flex-direction: row;
            align-items: center;
        }

        &__socials {
            gap:37.5px;
        }

        &__social {
            height: 48px;
            width: 48px;

            svg {
                height: 48px;
                width: 48px;
            }
        }

        &__picture {
            max-width:60%;
        }
    }

    .works {
        $this: &;
        width: calc(100vw - 150px);
        max-width: 100%;

        div {
            margin-bottom: 125px;
        }

        &__paragraph {
            flex-direction: column;
            align-items: center;
            gap: 37.5px;
        }

        &__link {
            width: 100%;
            height: 275px;
            border-radius: 30px;

            &::after {
                width: 65px;
                height: 65px;
                border-radius: 25px;
            }

            &:hover, &:focus, &:active{
                &:after {
                    width: 75px;
                    height: 75px;
                }
            }
        }
    }

    .animations{

        &__star {
            z-index: -1;
            position: absolute;
            top: -160px;
            left: -150px;
            width: 35%;
            max-width: 700px;
        }

        &__clover {
            z-index: -1;
            position: absolute;
            bottom: -150px;
            right: -150px;
            width: 35%;
            max-width: 700px;
        }
    }
}
