$c-bg: #13140d;
$c-outline-on-bg: #46483c;
$c-container: #444930;
$c-secondary-container: #214e46;
$c-text: #e4e3d7;
$c-text-container: #e0e6c4;
$c-text-secondary-container: #bcece1;

$size-text: 36px;
$size-text-mobile: 20px;
$size-text-tablet: 24px;
$size-text-small: 28px;
