@import url('https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap');

html {
  font-family: "Red Hat Text", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: $size-text;
  line-height: 1.5;
  color: $c-text;
}

.navbar{
  h3{
    font-family: 'Red Hat Text', serif;
    font-weight: 800;
    font-size: 1.73em;
    line-height: 1.5;
    color: $c-text;
    margin-top:18.75px;
  }

  &__button{
    font-family: "Red Hat Text", system-ui;
    font-weight: 400;
    font-style: normal;
    font-size: $size-text;
    line-height: 1.5;
    color: $c-text;
  }
}

.homepage{
  &__title{
    font-family: 'Baskervville', serif;
    font-weight: 400;
    font-size: 2.49em;
    line-height: 1.5;
    color: $c-text;
  }

  &__subtitle{
    font-family: "Red Hat Text", system-ui;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.5;
    color: $c-text;
  }
}

.works{
  h3{
    font-family: 'Red Hat Text', serif;
    font-weight: 800;
    font-size: 1.73em;
    line-height: 1.5;
    color: $c-text;
  }
  h4{
    font-family: 'Red Hat Text', serif;
    font-weight: 800;
    font-size: 1.2em;
    line-height: 1.5;
    color: $c-text;
  }
}

@media (max-width: 768px) {
  html {
    font-size: $size-text-mobile;
  }

  .navbar{
    $this: &;

    &__button{
      font-family: "Red Hat Text", system-ui;
      font-weight: 400;
      font-style: normal;
      font-size: 1.2rem;
      line-height: 1.5;
      color: $c-text;
    }
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  html {
    font-size: $size-text-tablet;
  }

  .navbar{
    $this: &;

    &__button{
      font-family: "Red Hat Text", system-ui;
      font-weight: 400;
      font-style: normal;
      font-size: 1.2rem;
      line-height: 1.5;
      color: $c-text;
    }
  }
}

@media (max-width: 1280px) and (min-width: 1025px) {
  html {
    font-size: $size-text-small;
  }

  .navbar{
    $this: &;

    &__button{
      font-family: "Red Hat Text", system-ui;
      font-weight: 400;
      font-style: normal;
      font-size: 1.2rem;
      line-height: 1.5;
      color: $c-text;
    }
  }
}